.tableContainer {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.contentTable {
  border-collapse: collapse;
  margin: 5px 0;
  font-size: 0.9em;
  min-width: 300px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.contentTable thead tr {
  background-color: #2e8b57;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  letter-spacing: 1px;
}

.contentTable th,
.contentTable td {
  padding: 13px 15px;
}

.contentTable tbody tr {
  border-bottom: 1px solid #dddddd;
}

.contentTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.contentTable tbody tr:last-of-type {
  border-bottom: 2px solid #2e8b57;
}
/* ============================================ */
.button {
  margin: 30px auto 5px;
  display: block;
  cursor: pointer;
  width: 250px;
  height: 50px;
  line-height: 46px;
  text-decoration: none;
  text-align: center;
  border-radius: 50px;
  border: 3px solid #2e8b57;
  color: #2e8b57;
  font-size: 20px;
  font-family: arial;
  position: relative;
  overflow: hidden;
  background: transparent;
  text-transform: uppercase;
  transition: all 0.35s;
  z-index: 111111;
}

.button:before,
.button:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background: #2e8b57;
  z-index: -1;
  transition: all 0.35s;
}

.button:before {
  opacity: 0.5;
}

.button:after {
  transition-delay: 0.2s;
}

.button:hover {
  color: #fff;
}

.button:hover:before,
.button:hover:after {
  top: 0;
}
