.container {
  margin-top: 20px;
  position: relative;
}

.accountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 28px;
  height: auto;
  margin-bottom: 30px;
}
.accountNumberInput {
  width: 200px;
  height: 30px;
  font-size: 20px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 1px 5px #00000061;
  transition: box-shadow 0.3s ease;
}

.accountNumberInput:focus {
  outline: none;
  border: 1px solid #2e8b57;
}
.searchButton {
  display: block;
  margin: 0 auto 30px;
  cursor: pointer;
  width: 180px;
  height: 50px;
  line-height: 46px;
  text-decoration: none;
  text-align: center;
  border-radius: 50px;
  border: 3px solid #2e8b57;
  color: #2e8b57;
  font-size: 20px;
  font-family: arial;
  position: relative;
  overflow: hidden;
  background: transparent;
  text-transform: uppercase;
  transition: all 0.35s;
  z-index: 111111;
}
.searchButton:before,
.searchButton:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background: #2e8b57;
  z-index: -1;
  transition: all 0.35s;
}

.searchButton:before {
  opacity: 0.5;
}

.searchButton:after {
  transition-delay: 0.2s;
}

.searchButton:hover {
  color: #fff;
}

.searchButton:hover:before,
.searchButton:hover:after {
  top: 0;
}
/* ========================================= */
.tableContainer {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.contentTable {
  border-collapse: collapse;
  margin: 5px 0;
  font-size: 0.9em;
  min-width: 180px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.contentTable thead tr {
  background-color: #2e8b57;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  letter-spacing: 1px;
}

.contentTable th,
.contentTable td {
  width: 140px;
  padding: 12px 15px;
}

.contentTable tbody tr {
  border-bottom: 1px solid #dddddd;
}

.contentTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.contentTable tbody tr:last-of-type {
  border-bottom: 2px solid #2e8b57;
}
/* ============================================== */
.noPickupsCard {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: red;
  /* width: 250px; */
  width: 100%;
  height: 50px;
  border-radius: 8px;
  box-shadow: 0 0 1px 1px #e4e7ec, 0 4px 4px #1d29390d;
}

/* ============================================== */
.loader {
  margin: 30px auto 10px;
  width: 30px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #2e8b57;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
