@font-face {
  font-family: Roboto-bold;
  src: url("./assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: Roboto-medium;
  src: url("./assets/fonts/Roboto-Medium.ttf");
}
body {
  user-select: none;
  font-family: "Roboto-medium";
  display: grid;
  justify-content: center;
  /* margin-top: 20px; */
}
.form-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.App {
  display: grid;
  width: auto;
  gap: 1rem;
}
.scan-container {
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;
  font-size: 14pt;
  padding-inline: 6 px;
}
.login-form {
  display: grid;
  gap: 1rem;
}
.scanner-container video {
  width: 300px;
  border: 1px solid white;
}
.form-control {
  display: grid;
  gap: 0.5rem;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: sans-serif;
  text-transform: capitalize;
}
.login-form button[type="submit"] {
  background-color: #eee;
  border-radius: 5px;
  border: 2px solid #aaa;
  height: 30px;
  text-transform: uppercase;
  font-weight: bold;
}
.scanned-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-top: 10px;
}
.scanned-title {
  font-weight: bold;
  font-weight: 17pt;
  color: #00347b;
}
.scanned-number {
  font-weight: bold;
  font-size: 17pt;
  color: #00347b;
}
.scanned {
  max-width: 300px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #eee;
}
.shipments-list {
  list-style-position: inside;
  list-style: none;
  display: grid;
  gap: 20px;
  margin-bottom: 30px;
  align-items: center;
}
.shipments-list li {
  position: relative;
}
.shipments-list > li::before {
  content: "";
  position: absolute;
  background-image: url("./assets/box.png");
  width: 30px;
  height: 40px;
  left: -43px;
  top: -5px;
}
.close {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 2px;
  font-weight: 400;
  border: 2px solid;
  display: grid;
  place-items: center;
}
.confirmation-btn {
  padding: 5px;
  border: 1px solid;
  display: grid;
  place-items: center;
  position: sticky;
  bottom: 5px;
  background-color: #00347b;
  color: white;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14pt;
}
.search-btn {
  padding: 5px;
  border: 1px solid;
  display: grid;
  place-items: center;

  width: 220px;
  height: 50px;
  bottom: 5px;
  background-color: #00347b;
  color: white;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14pt;
}
.confirm-btn {
  padding: 5px;
  border: 1px solid;
  display: grid;
  place-items: center;

  width: 290px;
  margin-left: 10px;
  height: 50px;
  bottom: 5px;
  background-color: #00347b;
  color: white;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14pt;
}
.confirmation-container {
  z-index: 11111111111;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 100vw;
  min-height: 100vh;
  display: grid;
  place-items: center;
  background-color: #aaa;
}
.confirmation-dialog {
  position: relative;
  bottom: 50px;
  width: 300px;
  height: 200px;
  border: 3px solid;
  background-color: white;
  display: grid;
  gap: 1rem;
  border-radius: 10px;
  padding: 2rem;
}
.confirmation-body {
  text-align: center;
  font-size: 18pt;
}
.confirmation-actions {
  display: flex;
  place-items: center;
  align-self: flex-end;
  justify-content: space-between;
}

.confirmation-actions div {
  border: 1px solid;
  padding: 1rem;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 90vh;
}

.login-image {
  margin-bottom: 5px;
  width: 150px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.display-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.pickups_contentTable__ni4gh thead tr {
  background-color: #00347b;
  color: #ffffff;
}
.pickups_contentTable__ni4gh tbody tr:last-of-type {
  border-bottom: 2px solid #00347b;
}
.shipmentOfPickups_contentTable__i3zzO thead tr {
  background-color: #00347b;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
  letter-spacing: 1px;
}
.shipmentOfPickups_contentTable__i3zzO tbody tr:last-of-type {
  border-bottom: 2px solid #00347b;
}
