.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  height: 10vh;
  flex-wrap: wrap;
}
.button {
  display: block;
  cursor: pointer;
  width: 250px;
  height: 50px;
  line-height: 46px;
  text-decoration: none;
  text-align: center;
  border-radius: 50px;
  border: 3px solid #2e8b57;
  color: #2e8b57;
  font-size: 20px;
  font-family: arial;
  position: relative;
  overflow: hidden;
  background: transparent;
  text-transform: uppercase;
  transition: all 0.35s;
  z-index: 111111;
}

.button:before,
.button:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  background: #2e8b57;
  z-index: -1;
  transition: all 0.35s;
}

.button:before {
  opacity: 0.5;
}

.button:after {
  transition-delay: 0.2s;
}

.button:hover {
  color: #fff;
}

.button:hover:before,
.button:hover:after {
  top: 0;
}
/* ================================= */
